<template>
  <a-layout class="custom-layout">
    <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
    <div class="batch-content auto" v-if="!isResult">
      <div class="batch-wrap auto">
        <div class="batch-title font16 auto">
          <b>批量导入人员</b>
        </div>
        <div class="batch-top auto">
          导入说明：请先
          <a-button size="small" @click="downloadHandle">下载模板</a-button>
          ，编辑后再导入
        </div>
        <a-spin :spinning="uploadLoading">
          <div class="batch-upload auto">
            <a-upload-dragger
              :showUploadList="false"
              name="file1"
              :action="config.api + 'user/import/upload/add'"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon excel-icon">
                <a-icon type="file-excel" />
              </p>
              <p class="ant-upload-text excel-text">
                将文件拖拽至此区域
              </p>
              <p class="ant-upload-hint excel-btn">
                <a-button size="small">上传文件</a-button>
              </p>
            </a-upload-dragger>
          </div>
        </a-spin>
      </div>
    </div>
    <div class="batch-content auto" v-else>
      <a-layout class="content">
        <a-layout-sider class="custom-sider">
          <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
          <div class="batch-menu">
            <ul>
              <li :class="menuIndex === 1 && 'cur'" @click="changeMenu(1)"><span>新增人员</span></li>
              <!-- <li :class="menuIndex === 2 && 'cur'" @click="changeMenu(2)"><span>重复人员</span></li> -->
              <li :class="menuIndex === 3 && 'cur'" @click="changeMenu(3)"><span>异常人员</span></li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="custom-content auto" style="position:relative;">
          <div class="topline auto" v-if="menuIndex === 1"></div>
          <div class="topline topline-yellow auto" v-if="menuIndex === 2"></div>
          <div class="topline topline-red auto" v-if="menuIndex === 3"></div>
          <a-spin :spinning="loading">
            <div class="content-title auto">
              <div class="coustom-title fl" v-if="menuIndex === 1">批量导入：新增人员（{{mes.normal || 0}}人）</div>
              <div class="coustom-title fl" v-if="menuIndex === 2">批量导入：重复人员（{{mes.repeat || 0}}人）</div>
              <div class="coustom-title fl" v-if="menuIndex === 3">批量导入：异常人员（{{mes.abnormal || 0}}人）</div>
            </div>
            <div class="coustom-title-mes auto">
              <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />您一共导入 {{mes.all || 0}} 个人员，其中：待导入人员 {{mes.normal || 0}} 人，有问题人员 {{mes.abnormal || 0}} 人
            </div>
            <div class="content-bar auto">
              <custom-page :total="total" @getPageMes="getPageMes" :page="page.current_page" :limit="page.page_size">
                <div slot="custom">
                  <a-button type="primary" size="small" class="m-r-5" @click="exportExcel"> 导出当前列表 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 1" @click="addAll" :disabled="isAdded"> 全部导入 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 2" @click="updateAll" :disabled="isUpdated"> 覆盖更新 </a-button>
                </div>
              </custom-page>
            </div>
            <div class="content-table auto" v-show="menuIndex === 1 || menuIndex === 2">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
              </a-table>
            </div>
            <div class="content-table auto" v-show="menuIndex === 3">
              <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="tabledata2" size="middle" :pagination="false">
                <div slot="error" slot-scope="text, record">
                  <a-tooltip>
                    <template slot="title">
                      <div v-for="(item, index) in text" :key="index">{{item}}</div>
                    </template>
                    <div class="ellipsis auto" style="width:100%;">
                      <span v-for="(item, index) in text" :key="index">{{item}}</span>
                    </div>
                  </a-tooltip>
                </div>
              </a-table>
            </div>
            <div class="content-bar auto">
              <custom-page :total="total" @getPageMes="getPageMes" :page="page.current_page" :limit="page.page_size">
                <div slot="custom">
                  <a-button type="primary" size="small" class="m-r-5" @click="exportExcel"> 导出当前列表 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 1" @click="addAll" :disabled="isAdded"> 全部导入 </a-button>
                  <a-button type="primary" size="small" class="m-r-5" v-show="menuIndex === 2" @click="updateAll" :disabled="isUpdated"> 覆盖更新 </a-button>
                </div>
              </custom-page>
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
    </div>
  </a-layout>
</template>

<script>
import { User } from '@/model'
export default {
  data () {
    return {
      isAdded: false,
      isUpdated: false,
      isResult: false,
      loading: false,
      menuIndex: 1,
      uploadId: '',
      mes: {},
      tablecolumns: [
        {
          title: '',
          dataIndex: '',
          width: 20,
        },
        {
          title: '学工号',
          dataIndex: 'schoolid',
          width: 110,
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 90,
        },
        {
          title: '证件类型',
          dataIndex: 'idCardType',
          width: 115,
        },
        {
          title: '证件号码',
          dataIndex: 'idCardNumber',
          width: 170,
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          customCell: () => {
            return {
              style: {
                'max-width': '130px',
              }
            }
          }
        },
        {
          title: '人员类别',
          dataIndex: 'type',
          customCell: () => {
            return {
              style: {
                'max-width': '115px',
              }
            }
          }
        },
        {
          title: '性别',
          dataIndex: 'sex',
          width: 70,
        },
        {
          title: '政治面貌',
          dataIndex: 'political',
          customCell: () => {
            return {
              style: {
                'max-width': '110px',
              }
            }
          }
        },
        {
          title: '入校时间',
          dataIndex: 'startDate',
          customCell: () => {
            return {
              style: {
                'max-width': '110px',
              }
            }
          }
        },
        {
          title: '预计离校时间',
          dataIndex: 'endDate',
          customCell: () => {
            return {
              style: {
                'max-width': '110px',
              }
            }
          }
        },
        {
          title: '人员状态',
          dataIndex: 'atSchoolStatus',
          customCell: () => {
            return {
              style: {
                'max-width': '80px',
              }
            }
          }
        }
      ],
      tabledata: [],
      tablecolumns2: [
        {
          title: '',
          dataIndex: '',
          width: 20,
        },
        {
          title: '错误信息',
          dataIndex: 'error',
          scopedSlots: { customRender: 'error' },
          ellipsis: true
        },
        {
          title: '学工号',
          dataIndex: 'schoolid',
          width: 110,
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 90,
        },
        {
          title: '证件类型',
          dataIndex: 'idCardType',
          width: 115,
        },
        {
          title: '证件号码',
          dataIndex: 'idCardNumber',
          width: 170,
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          customCell: () => {
            return {
              style: {
                'max-width': '130px',
              }
            }
          }
        },
        {
          title: '人员类别',
          dataIndex: 'type',
          customCell: () => {
            return {
              style: {
                'max-width': '115px',
              }
            }
          }
        },
        {
          title: '性别',
          dataIndex: 'sex',
          width: 70,
        },
        {
          title: '政治面貌',
          dataIndex: 'political',
          customCell: () => {
            return {
              style: {
                'max-width': '110px',
              }
            }
          }
        },
        {
          title: '入校时间',
          dataIndex: 'startDate',
          customCell: () => {
            return {
              style: {
                'max-width': '110px',
              }
            }
          }
        },
        {
          title: '预计离校时间',
          dataIndex: 'endDate',
          customCell: () => {
            return {
              style: {
                'max-width': '110px',
              }
            }
          }
        },
        {
          title: '人员状态',
          dataIndex: 'atSchoolStatus',
          customCell: () => {
            return {
              style: {
                'max-width': '80px',
              }
            }
          }
        }
      ],
      tabledata2: [],
      page: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      type: 'normal',
      uploadLoading: false,
      statusInterval: null
    }
  },
  methods: {
    addAll () {
      this.$confirm({
        title: '提示',
        content: '确定要执行导入吗',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          let res = await User.batchUserExecute(this.uploadId)
          if (!res['code']) {
            this.isAdded = true
            this.$message.success('导入成功！')
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    updateAll () {
      this.$confirm({
        title: '提示',
        content: '确定要覆盖更新吗',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          let res = await User.batchUserUpdate(this.uploadId)
          if (!res['code']) {
            this.isUpdated = true
            this.$message.success('更新成功！')
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    exportExcel () {
      if (this.menuIndex === 1) {
        window.open(this.config.api + 'user/tempfile/download?tempid=' + this.uploadId + '&type=normal', '_blank')
      }
      if (this.menuIndex === 2) {
        window.open(this.config.api + 'user/tempfile/download?tempid=' + this.uploadId + '&type=repeat', '_blank')
      }
      if (this.menuIndex === 3) {
        window.open(this.config.api + 'user/tempfile/download?tempid=' + this.uploadId + '&type=abnormal', '_blank')
      }
    },
    downloadHandle () {
      window.open(this.config.api + 'user/import/template/add', '_blank')
    },
    back () {
      this.$router.go(-1)
    },
    changeMenu (e) {
      this.menuIndex = e
      this.page.current_page = 1
      if (e === 1) {
        this.type = 'normal'
        this.openSuccess(this.uploadId, 'normal')
      }
      if (e === 2) {
        this.type = 'repeat'
        this.openSuccess(this.uploadId, 'repeat')
      }
      if (e === 3) {
        this.type = 'abnormal'
        this.openSuccess(this.uploadId, 'abnormal')
      }
    },
    handleChange(info) {
      this.uploadLoading = true
      const status = info.file.status;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (status === 'done') {
        // console.log(info)
        if (!info.file.response['code']) {
          this.uploadId = info.file.response.data
          this.statusInterval = setInterval(() => {
            this.getBatchUserMesStatus(this.uploadId)
          }, 1000)
        } else {
          // this.$message.error(info.file.response['description'])
          this.$message.error(this.errMsg[info.file.response['code']])
        }
      } else if (status === 'error') {
        this.uploadLoading = false
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    async getBatchUserMesStatus (id) {
      let res = await User.getBatchUserMesStatus({
        tempid: id
      })
      if (!res['code']) {
        if (res['data'] === 'success') {
          clearInterval(this.statusInterval)
          this.uploadLoading = false
          this.openSuccess(id, 'normal')
          this.getBatchUserMes(id)
        }
        if (res['data'] === 'error') {
          this.uploadLoading = false
          clearInterval(this.statusInterval)
          this.$message.error('处理失败，请重试！');
        }
      } else {
        this.uploadLoading = false
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getPageMes (obj) {
      this.page = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      this.openSuccess(this.uploadId, this.type, this.page)
    },
    async openSuccess (id, type) {
      this.loading = true
      let res = await User.getBatchUserResult({
        tempid: id,
        type: type,
        current_page: this.page.current_page,
        page_size: this.page.page_size
      })
      this.loading = false
      if (!res['code']) {
        if (type === 'abnormal') {
          this.tabledata2 = res['data']
        } else {
          this.tabledata = res['data']
        }
        this.total = res['total']
        this.isResult = true
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getBatchUserMes (id) {
      let res = await User.getBatchUserMes({
        tempid: id
      })
      if (!res['code']) {
        this.mes = res['data'] || {}
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    }
  }
};
</script>

<style scoped>
.batch-content {
  width: 100%;
  height: 100%;
}
.batch-wrap {
  width: 326px;
  margin: 0 auto;
}
.batch-title {
  text-align: center;
  line-height: 62px;
}
.batch-top {
  width: 324px;
  border: 1px solid #91D5FF;
  background: #E6F7FF;
  text-align: center;
  padding: 15px 0;
}
.batch-upload {
  margin-top: 15px;
}
.excel-icon i {
  color: rgba(0,0,0,0.10)!important;
  margin-top: 15px;
}
.excel-text {
  color: rgba(0,0,0,0.25)!important;
  font-size: 14px!important;
  margin-bottom: 10px!important;
}
.excel-btn {
  padding-bottom: 20px;
}

.content-bar {
  padding: 8px 0;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0,0,0,0.65);
}
.batch-menu ul li span {
  display: block;
  width: 140px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  margin-left: 20px;
  text-indent: 22px;
}
.batch-menu ul li.cur span {
  color: #226FBB;
  font-weight: bold;
  background: #E6F7FF;
}

.topline {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: #6DD400;
}
.topline-red {
  background: #E02020;
}
.topline-yellow {
  background: #F7B500;
}
</style>